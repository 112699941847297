.Accomplishment {
    width: 75%;
    padding: 5rem 2.5rem;
}

.Accomplishment-header {
    font-weight: 500;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
}

.Accomplishment__heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Accomplishment__heading-container button {
    padding: 0.25rem 2rem;
    border: none;
    background-color: rgba(70, 178, 240, 0.157);
    border-radius: 0.2rem;
    cursor: pointer;
}

.Accomplishment-input {
    width: 100%;
    padding: 0.5rem
}

.Accomplishment-textarea {
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    height: 10rem
}

.Accomplishment-btn {
    width: 100%;
    padding: 0.5rem
}

.Accomplishment-checkbox-container {
    display: flex;
    align-items: center;
}

.Accomplishment-checkbox-container p {
    margin-top: 0.9rem;
    margin-left: 0.5rem;
}

.Accomplishment-error-container p {
    color: rgb(161, 23, 23)
}

.Accomplishment-spinner-container {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Accomplishment-img {
    width: 15rem;
    margin-right: 4rem;
}