.SideNav {
    width: 25%;
    padding: 5rem 2.5rem;
    display: flex;
    flex-direction: column;
}

.SideNav-list-items {
    padding: 0.5rem;
    border-radius: 0.4rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    font-size: 1.1rem;
    color: black;
}

.SideNav-list-items:hover {
    text-decoration: none
}

.SideNav-list-items--active {
    background-color: rgba(70, 178, 240, 0.157);
}