.App {
  background-color: rgba(240, 248, 255, 0.514);
  height: 100vh;
}

.App-container {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
}

.SideNav-list-items {
  list-style: none;
  font-size: 1.4rem;
}